import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  useTheme,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import { RoutePath } from "../AppRoutes";
import { WorkshopPurchase } from "../../entities/workshop-purchase.entity";
import { useEffect, useState } from "react";
import { WorkshopPurchaseService } from "../../services/WorkshopPurchaseService";
import { useSnackbar } from "notistack";
import { WorkshopPurchaseItemState } from "../../entities/workshop-purchase-item-state.entity";
import { WorkshopPurchaseState } from "../../entities/workshop-purchase-state.entity";
import {
  NextState,
  WorkshopPurchaseItemStateService,
} from "../../services/WorkshopPurchaseItemStateService";

import { FieldError } from "../../types/FieldError";
import { WorkshopPurchaseItem } from "../../entities/workshop-purchase-item.entity";
import { WorkshopPurchaseItemService } from "../../services/WorkshopPurchaseItemService";
import PurchaseItemRow from "./PurchaseItemRow";

interface WorkshopPurchaseItemList {
  isEditionDisabled?: boolean;
  onPurchaseChange: Function;
  purchase: WorkshopPurchase;
}

export default function WorkshopPurchaseItemList({
  isEditionDisabled = false,
  onPurchaseChange,
  purchase,
}: WorkshopPurchaseItemList) {
  const [latestItemPrices, setLatestItemPrices] = useState<any[]>([]);
  const [nextStates, setNextStates] = useState<NextState[]>([]);
  const [errors, setErrors] = useState<FieldError[]>();
  const location = useLocation();
  const isBudgetView = location.pathname === RoutePath.BUDGET;

  const purchaseService = new WorkshopPurchaseService();
  const stateService = new WorkshopPurchaseItemStateService();
  const itemService = new WorkshopPurchaseItemService();

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const updateItemInPurchase = (
    updatedItem: WorkshopPurchaseItem,
    updatedPurchase?: WorkshopPurchase
  ) => {
    const updatedItems = purchase.workshopPurchaseItems.map((item) =>
      item.id === updatedItem.id ? updatedItem : item
    );

    const newPurchase = {
      ...purchase,
      workshopPurchaseItems: updatedItems,
    };

    onPurchaseChange(updatedPurchase || newPurchase);
  };

  const onStateChange = (
    updatedItem: WorkshopPurchaseItem,
    newStateId: number
  ): void => {
    const oldItem = { ...purchase.workshopPurchaseItems };
    itemService
      .update(updatedItem.id, {
        workshop_purchase_item_state_id: newStateId,
      })
      .then((resultItem) => {
        if ("errors" in resultItem) {
          setErrors(resultItem.errors);
          onPurchaseChange({
            ...purchase,
            workshopPurchaseItems: oldItem,
          });
        } else {
          let requiredItemStateId: number | undefined;
          let purchaseStateIdPair: number | undefined;
          let purchaseStateTransitionLabel: string = "";

          switch (resultItem.workshopPurchaseItemState.id) {
            case WorkshopPurchaseItemState.QUOTED_ID:
              requiredItemStateId = WorkshopPurchaseItemState.QUOTED_ID;
              purchaseStateIdPair = WorkshopPurchaseState.QUOTED_ID;
              purchaseStateTransitionLabel = WorkshopPurchaseState.QUOTED_LABEL;
              break;
            case WorkshopPurchaseItemState.VERIFICATION_PENDING_ID:
              requiredItemStateId =
                WorkshopPurchaseItemState.VERIFICATION_PENDING_ID;
              purchaseStateIdPair = WorkshopPurchaseState.DELIVERED_ID;
              purchaseStateTransitionLabel =
                WorkshopPurchaseState.DELIVERED_LABEL;
              break;
          }

          if (requiredItemStateId && purchaseStateIdPair) {
            const allItemsInState =
              purchase.workshopPurchaseItems.filter(
                (i) =>
                  i.workshopPurchaseItemState.id === requiredItemStateId &&
                  i.id !== resultItem.id
              ).length ===
              purchase.workshopPurchaseItems.length - 1;

            if (allItemsInState) {
              purchaseService
                .update(purchase.id, {
                  workshop_purchase_state_id: purchaseStateIdPair,
                })
                .then((updatedPurchase) => {
                  if ("errors" in updatedPurchase) {
                    setErrors(errors);
                  } else {
                    updateItemInPurchase(resultItem, updatedPurchase);
                    enqueueSnackbar(
                      `Se ha actualizado el estado de la compra a ${purchaseStateTransitionLabel}`,
                      { variant: "success" }
                    );
                  }
                });
              return;
            }
          }
          updateItemInPurchase(resultItem);
          setErrors([]);
        }
      })
      .catch((err) => console.error(err));
  };

  const getNextStates = (currentStateId: number): WorkshopPurchaseState[] => {
    return (
      nextStates.find((ns) => ns.state_id == currentStateId)?.next_states || []
    );
  };

  const saveNewPrice = (
    item: WorkshopPurchaseItem,
    mustUsePriceLatestQuote = false
  ) => {
    const newPrice = (
      document.getElementById(`item-price-${item.id}`) as any
    )?.value
      .replace("$", "")
      .replaceAll(".", "")
      .replace(",", ".");

    if (
      !newPrice ||
      newPrice == item.price ||
      (!mustUsePriceLatestQuote &&
        newPrice ==
          latestItemPrices.find((p) => p.id === item.id)?.workshopPurchaseItem
            .price)
    ) {
      return;
    }

    itemService
      .update(item.id, {
        price: Number(newPrice),
        quantity: Number(item.quantity),
      })
      .then((response) => {
        if ("errors" in response) {
          setErrors(response.errors);
        } else {
          const updatedItem = {
            ...item,
            price: newPrice,
          };
          updateItemInPurchase(updatedItem);
          setErrors([]);
        }
      })
      .catch((err) => console.error(err));
  };

  const onDescriptionBlur = async (
    item: WorkshopPurchaseItem,
    newDescription: string
  ) => {
    if (newDescription == item.description) {
      return;
    }

    const updatedItem = await itemService.update(item.id, {
      description: newDescription,
    });

    if ("errors" in updatedItem) {
      setErrors(updatedItem.errors);
      return;
    }

    if (errors?.find((e) => e.field === "description")) {
      setErrors(errors.filter((e) => e.field !== "description"));
    }

    updateItemInPurchase(updatedItem);
  };

  useEffect(() => {
    stateService.getNextStates().then((states) => setNextStates(states));
  }, []);

  useEffect(() => {
    purchase.workshopPurchaseItems.forEach((item, index) => {
      if (
        !purchase.budget.car.carModel?.name ||
        !purchase.budget.car.brand.name ||
        !purchase.budget.car.carVersion?.name ||
        item.workshopPurchaseItemState.id != WorkshopPurchaseState.QUOTE_ID
      ) {
        return;
      }

      itemService.getLatestQuote(item.id).then((res: any) => {
        if (!res?.workshopPurchaseItem?.price) {
          return;
        }

        setLatestItemPrices((prevVal) => {
          const updated = [...prevVal];
          updated[index] = res;

          return updated;
        });
      });
    });
  }, []);

  return (
    <>
      {isBudgetView ? (
        purchase.is_additional ? (
          <strong>Items adicionales</strong>
        ) : (
          <strong>Items</strong>
        )
      ) : purchase.is_additional ? (
        <strong>Items adicionales a comprar</strong>
      ) : (
        <strong>Items a comprar</strong>
      )}
      <TableContainer
        className="js-scrollable"
        component={Paper}
        sx={{
          margin: "10px 0px 20px 0px",
          minHeight: "120px",
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {latestItemPrices.length > 0 && (
                <TableCell
                  sx={{
                    maxWidth: "107px",
                    [theme.breakpoints.up("xl")]: {
                      maxWidth: "fit-content",
                      textOverflow: "clip",
                      whiteSpace: "nowrap",
                      overflow: "visible",
                    },
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  align="left"
                >
                  Última cotización
                </TableCell>
              )}
              <TableCell align="left">Item</TableCell>
              <TableCell align="left">Marca</TableCell>
              <TableCell align="left">Descripción</TableCell>
              <TableCell align="left">Precio</TableCell>
              <TableCell
                sx={{
                  maxWidth: "70px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  [theme.breakpoints.up("xl")]: {
                    maxWidth: "fit-content",
                    textOverflow: "clip",
                    whiteSpace: "nowrap",
                    overflow: "visible",
                  },
                }}
                align="left"
              >
                Cantidad
              </TableCell>
              <TableCell align="left">Total-Subitem</TableCell>
              <TableCell align="left">Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchase.workshopPurchaseItems.map((item) => (
              <PurchaseItemRow
                key={item.id}
                isEditionDisabled={isEditionDisabled}
                setLatestItemPrices={setLatestItemPrices}
                latestItemPrices={latestItemPrices}
                onStateChange={(event) =>
                  onStateChange(item, Number(event.target.value))
                }
                getNextStates={getNextStates}
                saveNewPrice={saveNewPrice}
                onDescriptionBlur={onDescriptionBlur}
                errors={errors}
                itemService={itemService}
                item={item}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
