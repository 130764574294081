import { WorkshopPurchasePriority } from "../../../../entities/WorkshopPurchasePriority.entity";
import PriorityIcon from "../../../Purchase/PriorityIcon";
import { useDarkMode } from "../../../ThemeContext";
import { TypeKanbanPurchase } from "./TypeKanbanPurchase";
import { Box, Typography, Avatar, Tooltip } from "@mui/material";

interface TaskProps {
  task: TypeKanbanPurchase;
  provided: any;
  handleOpen: any;
}

const PurchaseKanban = ({ task, provided, handleOpen }: TaskProps) => {
  const { isDarkMode } = useDarkMode();
  const {
    carId,
    modelCar,
    brandCar,
    company,
    priorityId,
    tags,
    name,
    lastName,
    workshopName,
    lastModificationDate,
    lastModificationColor,
    lastModificationBg,
  } = task;

  const priorityBbColor = (priorityID: number) => {
    switch (priorityID) {
      case WorkshopPurchasePriority.PUP_ID:
        return "#FFE4E6";
      case WorkshopPurchasePriority.SCHEDULE_ID:
        return "#dbeafe";
      case WorkshopPurchasePriority.URGENT_ID:
        return "#fef3c7";
      default:
        return "none";
    }
  };

  return (
    <Box
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={{
        width: "100%",
        cursor: "pointer!important",
        backgroundColor: isDarkMode ? "#2E363E" : "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 2,
        alignItems: "start",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        paddingX: 1.5,
        paddingY: 1.5,
        minWidth: "320px",
        minHeight: "150px",
        ":hover": {
          background: isDarkMode
            ? "#141A21"
            : "var(--ds-background-neutral-subtle-hovered,#F4F5F7)",
        },
      }}
      onClick={handleOpen}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {tags.map((tag) => (
            <Typography
              key={tag.title}
              sx={{
                paddingX: "10px",
                paddingY: "2px",
                fontSize: "12px",
                fontWeight: "medium",
                borderRadius: "4px",
              }}
              style={{ backgroundColor: tag.bg, color: tag.text }}
            >
              {tag.title === "R" || tag.title === "A" ? (
                <Tooltip
                  arrow
                  title={tag.title === "A" ? "Adicional" : "Recotización"}
                >
                  {tag.title}
                </Tooltip>
              ) : (
                tag.title
              )}
            </Typography>
          ))}
        </Box>
        <Box>
          {priorityId !== undefined ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                background: priorityBbColor(priorityId),
                borderRadius: "4px",
                padding: "2px",
              }}
            >
              <PriorityIcon id={priorityId} />
            </Box>
          ) : (
            <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
              "Sin prioridad"
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "13px",
                  fontWeight: "medium",

                  marginRight: "10px",
                }}
              >
                {carId}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "13px",
                  fontWeight: "medium",

                  marginRight: "10px",
                }}
              >
                {brandCar}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "13px",
                  fontWeight: "medium",
                }}
              >
                {modelCar}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "11px",
                color: "gray.500",
              }}
            >
              {company} | {workshopName}
            </Typography>
          </Box>
        </Box>
        <Box>
          {name && lastName ? (
            <Avatar sx={{ width: 24, height: 24 }}>
              <Typography sx={{ fontSize: "0.7rem", fontWeight: "bold" }}>
                {name.substring(0, 1)}
                {lastName.substring(0, 1)}
              </Typography>
            </Avatar>
          ) : (
            <Avatar sx={{ width: 24, height: 24 }}></Avatar>
          )}
        </Box>
      </Box>
      <Tooltip arrow title={"Ultimo cambio de estado"}>
        <Typography
          sx={{
            background: lastModificationBg,
            color: lastModificationColor,
            fontSize: "12px",
            borderRadius: "4px",
            padding: "3px",
          }}
        >
          {lastModificationDate}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default PurchaseKanban;
