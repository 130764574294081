import ApiService, { fetchAllPages } from "./ApiService";
import { BaseApplicationService } from "../interfaces/base-applicaton-service.interface";
import { WorkshopPurchase } from "../entities/workshop-purchase.entity";
import { Comment } from "../entities/comment.entity";
import { WorkshopPurchaseState } from "../entities/workshop-purchase-state.entity";

type Params = { page?: number; pageSize?: number; filters?: any };

type PartFilter = {
  part_brand_id?: { eq: number };
  brand_id?: { eq: number | undefined };
  car_model_id?: { in: (number | null)[] };
  car_version_id?: { in: (number | null)[] };
  repair_subitem_id?: { eq: number };
};

interface GetPartResponse {
  success: boolean;
  data: Array<{
    id: number;
    part_brand_id: number;
    brand_id: number | undefined;
    car_model_id: number | null;
    car_version_id: number | null;
    repair_subitem_id: number;
    part_number: string;
  }>;
}

export class WorkshopPurchaseService
  implements BaseApplicationService<WorkshopPurchase, Params>
{
  private purchasesStatesToInclude = [
    WorkshopPurchaseState.QUOTE_ID,
    WorkshopPurchaseState.QUOTED_ID,
    WorkshopPurchaseState.BUY_ID,
    WorkshopPurchaseState.SPEC_ID,
    WorkshopPurchaseState.DELIVERED_ID,
    WorkshopPurchaseState.FINISHED_ID,
    WorkshopPurchaseState.EXPIRED_ID,
  ];

  async findAllPurchases(): Promise<WorkshopPurchase[]> {
    let activePurchases: WorkshopPurchase[] = [];
    let page = 1;
    let totalPages = 1;

    while (page <= totalPages) {
      const response = await this.find({
        page,
        filters: {
          "WorkshopPurchaseSearch[workshop_purchase_state_id][]":
            this.purchasesStatesToInclude,
        },
      });

      activePurchases = [...activePurchases, ...response.data];
      totalPages = Math.ceil(response.totalCount / 20);
      page++;
    }
    return activePurchases;
  }

  async find(params?: Params): Promise<{
    data: WorkshopPurchase[];
    totalCount: number;
    pageSize: number;
  }> {
    const { page = 1, pageSize = 20, filters } = params || {};

    const response = await ApiService.get("/api_v2/workshop-purchase/index", {
      params: { page, pageSize, ...filters },
    });

    const filteredBudgetList = response?.data.data.filter((b: any) => b.budget);

    const purchases =
      filteredBudgetList.map(
        (p: WorkshopPurchase) => new WorkshopPurchase(p)
      ) || [];

    const totalCount = parseInt(
      response.headers?.["x-pagination-total-count"] || "0",
      10
    );

    const perPage = parseInt(
      response.headers?.["x-pagination-per-page"] || "20",
      10
    );

    return { data: purchases, totalCount, pageSize: perPage };
  }

  async findOneById(id: number): Promise<WorkshopPurchase | undefined> {
    const response = await ApiService.get(
      `/api_v2/workshop-purchase/view?id=${id}`
    );

    const purchase = response?.data.data;

    return purchase ? new WorkshopPurchase(purchase) : undefined;
  }

  findOne(_params: Params): Promise<WorkshopPurchase> {
    throw new Error("Method not implemented.");
  }

  async history(purchaseId: number[], purchaseItemIds: number[] = []) {
    const models: Record<string, number[]> = {
      "backend\\models\\WorkshopPurchase": purchaseId,
    };

    if (purchaseItemIds.length > 0) {
      models["backend\\models\\WorkshopPurchaseItem"] = purchaseItemIds;
    }

    const filters = { models };

    const allHistoryData = await fetchAllPages<any>(
      `/api_v2/audit-trail/index`,
      20,
      undefined,
      "POST",
      filters
    );

    const filteredHistoryData = allHistoryData.filter((item: any) => {
      if (
        item.model === "backend\\models\\WorkshopPurchaseItem" &&
        item.action === "CREATE" &&
        item.field === null &&
        item.formatted_new_value === null &&
        item.formatted_old_value === null
      ) {
        return false;
      }
      return true;
    });
    return filteredHistoryData;
  }

  async getComments(id: number) {
    const response = await ApiService.get(
      `/api_v2/workshop-purchase/comments?id=${id}`
    );

    const comments = response?.data.data.map(
      (c: any) =>
        new Comment({
          ...c,
          created_at: new Date(c.created_at),
        })
    );

    const sortedComments = comments.sort(
      (a: any, b: any) => b.created_at - a.created_at
    );

    return sortedComments;
  }

  async update(
    purchaseId: number,
    purchase: Partial<WorkshopPurchase>
  ): Promise<WorkshopPurchase> {
    try {
      const response = await ApiService.post(
        `/api_v2/workshop-purchase/update?id=${purchaseId}`,
        purchase
      );

      if (response.data && response.data.success === false) {
        throw new Error(response.data.data);
      }

      if (!response.data || !response.data.data) {
        throw new Error("Unexpected response format");
      }

      return new WorkshopPurchase(response.data.data);
    } catch (error: any) {
      console.error("Error in WorkshopPurchaseService.update:", error);
      throw new Error(error.message || "Unknown error");
    }
  }

  async updatePart(partData: {
    part_brand_id: number | null;
    brand_id: number;
    car_model_id: number | null;
    car_version_id: number | null;
    repair_subitem_id: number;
    part_number: string | null;
  }) {
    try {
      const response = await ApiService.post("/api_v2/part/update", partData);

      if (response.data.success) {
        return response.data.data;
      } else {
        throw new Error("Error al actualizar o crear la parte.");
      }
    } catch (error) {
      console.error("Error en la actualización/creación de la parte:", error);
      throw error;
    }
  }

  async getPart(filter: PartFilter): Promise<GetPartResponse> {
    try {
      const response = await ApiService.post<GetPartResponse>(
        "/api_v2/part/index",
        { filter }
      );

      if (response.data.success) {
        return response.data;
      } else {
        throw new Error("Error al obtener partes: operación no exitosa.");
      }
    } catch (error) {
      console.error("Error al obtener partes:", error);
      throw error;
    }
  }

  async getPartBrands(): Promise<{ id: number; name: string }[]> {
    try {
      const allBrands = await fetchAllPages<{ id: number; name: string }>(
        "/api_v2/part-brand/index"
      );
      return allBrands;
    } catch (error) {
      console.error("Error al obtener las marcas de partes:", error);
      throw error;
    }
  }
}
